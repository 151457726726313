import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import {
  Hero,
  Nav,
  Baner,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment"

const Investment = ({ location }) => {
  const title = "Нове Сліховіце"

  return (
    <Layout
      location={location}
      seo={{
        title: "Нове Сліховіце - Новий вимір міського життя",
        description:
          "Нові апартаменти в Кельце з видом на монастир Карчувка. Високий стандарт, чудове розташування та багато зелені. 51 квартира, площею 29-99 м2.",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Кельце", url: "/ua/kvartyry/keltse" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} />
      <Nav title={title} investmentRaw="nove_slichowice" />
      <Baner />
      <Gallery />
      <About title={title} />
      <Location />
      <Convenients />
      <ContactForm
        formType="sale"
        investment="Nove Ślichowice"
        investmentRaw="nove_slichowice"
      />
      {/* <Popup type="kielce" /> */}
    </Layout>
  )
}

export default Investment
